  /* Color Select */
  class ColorSwatch extends HTMLElement {
    constructor() {
      super();
      this.handle = this.getAttribute('handle');
      this.sectionId = this.getAttribute('section-id')
      this.variantRadios = document.querySelector('variant-radios');
      this.input = this.querySelector('input');

      this.addEventListener('change', this.onColorUpdate.bind(this))
    }

      onColorUpdate(event) {
        event.preventDefault();
        fetch(`/products/${this.handle}?section_id=${this.sectionId}`)
          .then((response) => response.text())
          .then((responseText) => {
            const html = new DOMParser().parseFromString(responseText, 'text/html').querySelector('.js-product-main');
            let fetchColorSwatches = html.querySelector('#ColorVariants');
            let limitedLabel = fetchColorSwatches.querySelector('.js-selected-limited');
            let classicLabel = fetchColorSwatches.querySelector('.js-selected-classic');
            document.querySelector(`swatch-selector[handle="${this.handle}"]`).checked = true;

            let currentRadios = document.querySelector('#ColorVariants');
            let currentLimitedLabel = currentRadios.querySelector('.js-selected-limited');
            let currentClassicLabel = currentRadios.querySelector('.js-selected-classic');

            if (limitedLabel) {
              currentLimitedLabel.innerHTML = limitedLabel.innerHTML;
            } else {
            }
            if (classicLabel) {
              currentClassicLabel.innerHTML = classicLabel.innerHTML;
            } else {
            }

            let initalLimitedScroll = document.querySelector('#limited-swatch-container');
            if (initalLimitedScroll) initalLimitedScroll = initalLimitedScroll.scrollLeft;
            let initalClassicScroll = document.querySelector('#classic-swatch-container');
            if (initalClassicScroll) initalClassicScroll = initalClassicScroll.scrollLeft;

            fetchColorSwatches.innerHTML = currentRadios.innerHTML;

            document.querySelector('.js-product-main').innerHTML = html.innerHTML;
            //  var api = new Yotpo.API(yotpo);
            //  api.refreshWidgets();
            yotpoWidgetsContainer.initWidgets();
            document.querySelector(`swatch-selector[handle="${this.handle}"]`).querySelector('input').checked = true;

            let limitedContainer = document.querySelector('#limited-swatch-container');
            if (limitedContainer) {
              let limitedCheckedRadio = limitedContainer.querySelector('input[type="radio"]:checked');
              limitedContainer.scrollLeft = initalLimitedScroll;
            }

            let classicContainer = document.querySelector('#classic-swatch-container');
            if (classicContainer) {
              let classiCheckedRadio = classicContainer.querySelector('input[type="radio"]:checked');
              classicContainer.scrollLeft = initalClassicScroll;
            }

            window.history.replaceState({}, '', `/products/${this.handle}`);
          })
          .catch((e) => {
            console.error(e);
          });
      }

  }

customElements.define('swatch-selector', ColorSwatch);
